import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function LanguageSkeleton() {
  return (
    <Stack spacing={2} p={6}>
      <Typography variant="h5" gutterBottom component="div">
        {" "}
        Loading ...
      </Typography>
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" width={"100%"} height={118} />
      <Skeleton variant="rectangular" width={"100%"} height={118} />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" width={"100%"} height={118} />
      <Skeleton variant="text" animation="wave" />
    </Stack>
  );
}
