import Typography from "@mui/material/Typography";
import { FormikErrors, withFormik } from "formik";
import { styles } from "./styles";
import { NoteDialogDialogTitle } from "./NoteDialogDialogTitle";
import { NoteDialogProps } from "./NoteDialogProps";
import NoteForm from "./NoteForm";
import NoteModel from "../../model/NoteModel";

export interface NoteFormProps {
  sx?: Array<object | boolean> | object;
  note?: NoteModel;
  isNew: boolean;
  languageId: number;
  onClose: () => void;
  onSave: () => void;
}

export interface NoteDialogNewProps extends NoteDialogProps {
  onSave: () => void;
}

export default function NoteNew(props: NoteDialogNewProps) {
  const { languageId, languageName, handleClose, onSave } = props;

  const NoteFormikForm = withFormik<NoteFormProps, NoteModel>({
    validate: (values: NoteModel) => {
      const errors: FormikErrors<NoteModel> = {};

      if (!values.note) {
        errors.note = "Required";
      }

      return errors;
    },
    handleSubmit: () => {
      /* TODO document why this method 'handleSubmit' is empty */
    },
  })(NoteForm);

  return (
    <div>
      <NoteDialogDialogTitle id="note-new-dialog-title" onClose={handleClose}>
        <Typography sx={styles.noteDialogTitle}>Add Note</Typography>
        <Typography sx={styles.noteDialogSubTitle}>{languageName}</Typography>
      </NoteDialogDialogTitle>
      <NoteFormikForm
        isNew={false}
        languageId={languageId}
        onClose={handleClose}
        onSave={onSave}
      />
    </div>
  );
}
