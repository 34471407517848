import React, { useState } from "react";
import { OPT_SEARCH_ALL } from "../utils/SearchingOptions";

export const useFilterCheck = (optionChecked: string = OPT_SEARCH_ALL) => {
  const [state, setState] = useState<string>(optionChecked);

  const handleCheckClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  return {
    filterChecked: state,
    handleCheckClick,
  };
};
