import { pxToRem, theme } from "../../utils/CustomTheme";

const textStyle = {
  fontWeight: 400,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
};

const radioLabel = {
  "& .MuiFormControlLabel-label": {
    fontSize: pxToRem(13),
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
  },
};

export const styles = {
  stackTitle: textStyle,
  check: {
    ...textStyle,
    mr: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: pxToRem(14),
      textTransform: "Capitalize",
    },
    "& .MuiCheckbox-root": {
      pt: 0,
      pb: 0,
    },
  },
  radio: {
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  radioLabel: radioLabel,
  radioLabelChecked: {
    "& .MuiFormControlLabel-label": {
      ...radioLabel["& .MuiFormControlLabel-label"],
      color: theme.palette.text.primary,
    },
  },
  textResults: {
    fontSize: pxToRem(12),
  },
};
