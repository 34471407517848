import { ReactElement } from "react";
import {
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  BoxModuleLinkTab,
  ModuleLinkTab,
} from "./styles";

export interface LinkTabProps {
  className?: string;
  label?: string;
  href?: string;
  icon?: ReactElement;
  selected?: boolean;
  value?: number;
  isSubTab?: boolean;
  isCollapsed?: boolean;
  onChange?: (option: number) => void;
}

const renderArrow = (
  isSelected: boolean,
  isSubTab: boolean,
  isCollapsed: boolean
) => {
  if (isSubTab) {
    return <ArrowRightIcon />;
  }

  if (isSelected || isCollapsed) {
    return <ArrowUpIcon />;
  }

  return <ArrowDownIcon />;
};

const ModuleTab = (props: LinkTabProps) => {
  const {
    selected: isSelected = false,
    isSubTab = false,
    isCollapsed = false,
  } = props;

  return (
    <BoxModuleLinkTab>
      <ModuleLinkTab {...props} />
      {renderArrow(isSelected, isSubTab, isCollapsed)}
    </BoxModuleLinkTab>
  );
};

export default ModuleTab;
