import { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import NoteList from "./NoteList";
import { styles } from "./styles";
import { NoteDialogDialogTitle } from "./NoteDialogDialogTitle";
import { NoteDialogProps } from "./NoteDialogProps";

export default function NoteDialogList(props: NoteDialogProps) {
  const { open, languageId, languageName, handleClose, handleAddNote } = props;
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <NoteDialogDialogTitle id="notes-dialog-title" onClose={handleClose}>
        <Typography sx={styles.noteDialogTitle}>Notes</Typography>
        <Typography sx={styles.noteDialogSubTitle}>{languageName}</Typography>
      </NoteDialogDialogTitle>
      <DialogContent dividers>
        <Box
          id={`notes-dialog-content-${languageId}`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <NoteList languageId={languageId} />
        </Box>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button
          sx={styles.notesCancelButton}
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          sx={styles.noteAddButton}
          variant="contained"
          autoFocus
          onClick={handleAddNote}
          startIcon={<EditIcon />}
        >
          Add Note
        </Button>
      </DialogActions>
    </>
  );
}
