import React, { useState } from "react";
import {
  Pagination,
  Stack,
  Grid,
  Alert,
  Typography,
  Container,
  Button,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSearchParams } from "react-router-dom";
import { styles } from "./styles";
import LanguageCardList from "./LanguageCardList";
import LanguageModel from "../../model/LanguageModel";
import MetaPagination from "../../types/MetaPagination";
import SearchField from "../../components/SearchField/SearchField";
import SearchStackRadios from "../../components/SearchStackRadios/SearchStackRadios";
import { useLanguageQuery } from "../../hooks/useLanguageQuery";
import { useFilterCheck } from "../../hooks/useFilterCheck";

const limit = 50;

const LanguagesList = () => {
  const [searchParam] = useSearchParams();
  const searchTextByQuery = searchParam.get("q") || "";

  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [searchText, setSearchText] = useState<string>(searchTextByQuery);

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const onChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchTextValue = event.target?.value;
    setSearchText(searchTextValue);
  };

  const onSuccesQuery = (response: {
    data: LanguageModel[];
    meta: MetaPagination;
  }) => {
    const { data: languagesList, meta: metaData } = response;

    setLanguages(languagesList);
    setCount(metaData.pagination.count);
    setTotalPages(metaData.pagination.total_pages);
  };

  const onErrorQuery = (err: Error): void => {
    setLanguages([]);
    setCount(0);
    console.log(err);
  };

  const { filterChecked, handleCheckClick } = useFilterCheck();

  const { isLoadingLanguages, isFetching, isError, getAllLanguages } =
    useLanguageQuery({
      searchText,
      page,
      limit,
      filterChecked,
      onSuccess: onSuccesQuery,
      onError: onErrorQuery,
    });

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} pb={3} sx={{ backgroundColor: "white" }}>
        <Container maxWidth="md">
          <Grid container mt={0} spacing={3}>
            <Grid item xs={12}>
              <SearchStackRadios
                handleClick={handleCheckClick}
                optionChecked={filterChecked}
              />
            </Grid>
            <Grid item sm={10} xs={12}>
              <SearchField
                searchText={searchText}
                onChange={onChangeSearchText}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className="input-group-append">
                <LoadingButton
                  variant="outlined"
                  onClick={getAllLanguages}
                  size="large"
                  sx={styles.newSearchButton}
                  loading={isLoadingLanguages || isFetching}
                >
                  Search
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Divider sx={{ width: "100 %" }} />
      <Grid item xs={12} pb={3} pt={3} sx={styles.containerLanguageList}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <Typography sx={styles.searchResultTitle}>
                  {count} entries found
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!isError ? (
                <LanguageCardList list={languages} />
              ) : (
                <Alert severity="error">
                  This is an error alert — try again!
                </Alert>
              )}
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={2}>
                <Typography sx={styles.searchResultTitle}>
                  End of Results
                </Typography>
              </Grid>
              <Grid item xs={6.5} alignItems="center">
                <Divider />
              </Grid>
              <Grid item xs={3.5} sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={styles.newSearchButton}
                  href="/language"
                >
                  Add a new Language
                </Button>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
              {count > 0 ? (
                <Grid item xs={3}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default LanguagesList;
