import React from "react";
import { InputAdornment } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { styles } from "./styles";
import { TextFastField } from "../../components/Form/TextFastField/TextFastField";

interface SearchFieldProps {
  searchText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const SearchField = ({
  searchText,
  onChange: onChangeSearchText,
  onKeyPress,
}: SearchFieldProps) => {
  return (
    <TextFastField
      fullWidth
      id="search-text-value"
      label="Type your search"
      onChange={onChangeSearchText}
      onKeyPress={onKeyPress}
      value={searchText}
      InputProps={{
        style: { fontWeight: 500, backgroundColor: "#fff" },
        endAdornment: (
          <InputAdornment position="end">
            <Search sx={(theme) => ({ color: theme.palette.primary.main })} />
          </InputAdornment>
        ),
      }}
      sx={styles.searchField}
    />
  );
};

export default SearchField;
