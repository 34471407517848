import { ElementType } from "react";
import { Theme, MUIStyledCommonProps } from "@mui/system";
import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Language from "@mui/icons-material/Language";
import List from "@mui/material/List";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";
import ModuleTab, { LinkTabProps } from "./ModuleTab";
import SideMenu from "./SideMenu";
import LinkTab from "./LinkTab";
import { default as LogoLinguaSource } from "../Logo/Logo";

export const LeftNav1 = styled("div")(({ theme }) => ({
  backgroundColor: alpha(theme.palette.text.primary, 1),
  display: "flex",
  position: "relative",
  isolation: "isolate",
  flexDirection: "column",
  height: "1024px",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0px 0px 32px 0px",
  boxSizing: "border-box",
}));

export const SideMenuTop = styled("div")({
  display: "flex",
  position: "relative",
  isolation: "isolate",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "0px",
  boxSizing: "border-box",
  margin: "0px",
  width: "100%",
});

export const LogoFrame = styled("div")({
  display: "flex",
  position: "relative",
  isolation: "isolate",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "24px 44px",
  boxSizing: "border-box",
  margin: "0px",
  width: "100%",
});

export const LogoLinguaSource1 = styled(LogoLinguaSource)(() => ({
  height: "32px",
  width: "168px",
  margin: "0px",
}));

export const SideMenu1 = styled(SideMenu)(() => ({
  margin: "0px",
}));

export const SideMenuBottom = styled("div")({
  display: "flex",
  position: "absolute",
  isolation: "isolate",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "0px",
  boxSizing: "border-box",
  width: "192px",
  left: "32px",
  top: "903px",
});

export const Button2 = styled(Button)(({ theme }) => ({
  alignSelf: "stretch",
  margin: "0px",
  justifyCcontent: "center",
  alignItems: "flex-start",
  border: "1px solid #68728C",
  borderRadius: "2px",
  color: theme.palette.secondary.main,
  fontSize: theme.typography.pxToRem(13),
}));

export const Frame51 = styled("div")({
  display: "flex",
  position: "relative",
  isolation: "isolate",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "0px",
  boxSizing: "border-box",
  alignSelf: "stretch",
  height: "32px",
  margin: "21px 0px 0px 0px",
});

export const Account = styled("div")(() => ({
  textAlign: "left",
  whiteSpace: "pre-wrap",
  color: alpha("#D8DEE3", 1),
  fontStyle: "normal",
  fontFamily: "Inter",
  fontWeight: "600",
  fontSize: "13px",
  letterSpacing: "0px",
  textDecoration: "none",
  lineHeight: "100%",
  textTransform: "none",
  position: "absolute",
  left: "44px",
  top: "10px",
}));

export const SignOut = styled("div")(() => ({
  textAlign: "left",
  whiteSpace: "pre-wrap",
  color: alpha("#D8DEE3", 1),
  fontStyle: "normal",
  fontFamily: "Inter",
  fontWeight: "600",
  fontSize: "13px",
  letterSpacing: "0px",
  textDecoration: "none",
  lineHeight: "100%",
  textTransform: "none",
  width: "55px",
  height: "11px",
  position: "absolute",
  left: "132px",
  top: "10px",
}));

export const Rectangle22 = styled("div")(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 1),
  width: "32px",
  height: "32px",
  position: "absolute",
  left: "0px",
  top: "0px",
}));

//-----------------------------------------------
// Module Tab
//-----------------------------------------------
const KeyboardArrow = {
  idth: "20px",
  height: "20px",
  position: "absolute" as "absolute",
  bottom: "30px",
  right: "24px",
};

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)(() => KeyboardArrow);

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)(() => KeyboardArrow);

export const ArrowRightIcon = styled(KeyboardArrowRightIcon)(() => ({
  width: "20px",
  height: "20px",
  position: "absolute" as "absolute",
  bottom: "20px",
  right: "24px",
}));

const getModuleTabBackgroundColor = (
  theme: Theme,
  isSelected: boolean,
  isSubTab: boolean
) => {
  if (isSelected && isSubTab) {
    return theme.palette.common.white;
  } else if (!isSelected && isSubTab) {
    return alpha(theme.palette.common.black, 0.12);
  } else if (isSelected && !isSubTab) {
    return "#30384D";
  }

  return theme.palette.text.primary;
};

const getModuleTabTextColor = (
  theme: Theme,
  isSelected: boolean = false,
  isSubTab: boolean = false
) => {
  if (isSelected && isSubTab) {
    return theme.palette.text.primary;
  } else if (!isSelected && isSubTab) {
    return theme.palette.secondary.light;
  }

  return theme.palette.common.white;
};

export const ModuleLinkTab = styled(LinkTab)(
  (props: MUIStyledCommonProps<Theme> & LinkTabProps) => ({
    isolation: "isolate",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "30px 24px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px",
    LineHeight: "16.94px",
    width: "100%",
    backgroundColor: props.theme
      ? getModuleTabBackgroundColor(
          props.theme,
          !!props.selected,
          !!props.isSubTab
        )
      : "",
    color: props.theme
      ? getModuleTabTextColor(props.theme, !!props.selected, !!props.isSubTab)
      : "",
    "&:hover": {
      color:
        props.theme && props.isSubTab ? props.theme.palette.primary.main : "",
      backgroundColor:
        props.theme && props.isSubTab ? props.theme.palette.common.white : "",
      filter:
        props.theme && props.isSubTab
          ? `drop-shadow(0px 4px 4px ${alpha(
              props.theme.palette.common.black,
              0.25
            )})`
          : "",
    },
    borderBottom: props.selected && !props.isSubTab ? "1px solid #2A2F3D" : "",
  })
);

export const BoxModuleLinkTab = styled(Box)(() => ({
  width: "100%",
  position: "relative" as "relative",
}));

//-----------------------------------------------
// Side Menu
//-----------------------------------------------

const iconStyle = () => ({
  width: "22px",
  height: "20px",
  margin: "0 8px 0 0",
});

export const LanguageIcon = styled(Language)(iconStyle);

export const LanguageSearchIcon = styled(NoiseControlOffIcon)(iconStyle);

export const AgreementSearchIcon = LanguageSearchIcon;
export const AgreementAddIcon = LanguageSearchIcon;

export const AgreementsIcon = styled(TextSnippetOutlinedIcon)(iconStyle);

export const MainModuleTab = styled(ModuleTab)(() => ({
  padding: "29px 24px",
  borderWidth: "1px 0px",
  borderStyle: "solid",
  borderColor: "#2A2F3D",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: "#373F54",
  },
  "&$selected": {
    backgroundColor: "#004C9B",
  },
}));

export const SubModuleTab = styled(ModuleTab)(({ theme }) => ({
  padding: "20px 24px",
  minHeight: "60px",
  opacity: 1,
  boxSizing: "border-box",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    color: "#1890ff",
    backgroundColor: theme.palette.common.white,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const SideTabs = styled(List)<{ component?: ElementType }>(
  ({ theme }) => ({
    width: "100%",
    padding: 0,
    filter: `drop-shadow(0px 4px 4px ${alpha(
      theme.palette.common.black,
      0.25
    )})`,
  })
);

export const SubSideTabs = styled(List)<{ component?: ElementType }>(() => ({
  width: "100%",
  padding: 0,
}));
