import React, { useState, useEffect, useCallback, SetStateAction } from "react";
import { useQuery } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LanguageModel from "../../../model/LanguageModel";

export interface BaseAutocompleteProps {
  id: string;
  name: string;
  value: string;
  error: boolean;
  setValues: (
    values: SetStateAction<LanguageModel>,
    shouldValidate?: boolean | undefined
  ) => void;
}

interface AutocompleteProps extends BaseAutocompleteProps {
  fetchData: () => Promise<{ name: string }[]>;
}

export const AutocompleteCustom = (props: AutocompleteProps) => {
  const {
    id,
    name,
    value: autocompleteValue,
    error,
    fetchData,
    setValues,
  } = props;

  const [options, setOptions] = useState<{ name: string }[]>([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(autocompleteValue);

  const onSuccess = (resultOptions: { name: string }[]): void => {
    setOptions(resultOptions);
  };

  const onError = (resultError: Error): void => {
    console.error(resultError);
    setOptions([]);
  };

  const { isLoading, isFetching, refetch } = useQuery<
    { name: string }[],
    Error
  >(`query-get-autocomplete-data-${id}`, fetchData, {
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  });
  const loading = isLoading || isFetching;

  const performFetchData = useCallback((): void => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (open) {
      performFetchData();
    }
  }, [open, performFetchData]);

  useEffect(() => {
    if (loading) {
      setOptions([]);
    }
  }, [loading]);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onChangeCustom = (
    _: React.SyntheticEvent<Element, Event>,
    inputValue: { name: string } | null
  ) => {
    setValues((values: any) => {
      return {
        ...values,
        [name]: inputValue?.name,
      };
    });

    setValue(inputValue?.name || "");
  };

  return (
    <Autocomplete
      id={id}
      open={open}
      options={options}
      loading={loading}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChangeCustom}
      isOptionEqualToValue={(option, optionValue) =>
        option.name === optionValue.name
      }
      getOptionLabel={(option: { name: string }) => option.name}
      value={{ name: value }}
      renderInput={(params) => (
        <TextField {...params} name={name} fullWidth={true} error={error} />
      )}
    />
  );
};

export default AutocompleteCustom;
