import { useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import LanguageModel from "../model/LanguageModel";
import MetaPagination from "../types/MetaPagination";
import LanguageRequestParameters from "../types/LanguageRequestParameters";
import LanguageDataService from "../services/LanguageService";

const getRequestParams = (
  searchTextInput: string,
  pageInput: number,
  limitInput: number
): LanguageRequestParameters => {
  let params: LanguageRequestParameters = {};

  if (searchTextInput) {
    params["name"] = searchTextInput;
  }

  if (pageInput) {
    params["page"] = pageInput - 1;
  }

  if (limitInput) {
    params["limit"] = limitInput;
  }

  return params;
};

interface UseLanguageQueryProps {
  searchText: string;
  page: number;
  limit: number;
  filterChecked: string;
  onSuccess: (response: {
    data: LanguageModel[];
    meta: MetaPagination;
  }) => void;
  onError: (err: Error) => void;
}

export const useLanguageQuery = ({
  searchText,
  page,
  limit,
  // filterChecked,
  onSuccess,
  onError,
}: UseLanguageQueryProps) => {
  const {
    isLoading: isLoadingLanguages,
    isFetching,
    isError,
    refetch: getLanguages,
  } = useQuery<{ data: LanguageModel[]; meta: MetaPagination }, Error>(
    "query-search-languages",
    async () => {
      const params = getRequestParams(searchText, page, limit);
      // The app will have to do multiple requests to multiple endpoints to wrap the results.
      // Each request depends on filters (bible, media Id, translation). So, the next lines
      // should be uncomment when the others endpoints are done.
      //
      // if (filterChecked === OPT_SEARCH_ALL || filterChecked === OPT_SEARCH_LANGUAGE) {
      //   return LanguageDataService.findAll(params);
      // }

      return LanguageDataService.findAll(params);
    },
    {
      retry: 1,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  const getAllLanguages = useCallback((): void => {
    getLanguages();
  }, [getLanguages]);

  useEffect(() => {
    getAllLanguages();
  }, [page, getAllLanguages]);

  return {
    isLoadingLanguages,
    isFetching,
    isError,
    getAllLanguages,
  };
};
