import AutocompleteCustom, {
  BaseAutocompleteProps,
} from "./AutocompleteCustom";
import CountryService from "../../../services/CountryService";

interface CountryFieldProps extends BaseAutocompleteProps {}

export const CountryField = (props: CountryFieldProps) => {
  const fetchData = async (): Promise<{ name: string }[]> => {
    const { data: resultCountries } = await CountryService.all();
    return resultCountries;
  };

  return <AutocompleteCustom {...props} fetchData={fetchData} />;
};

export default CountryField;
