import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface DraggableDialogConfirmationProps {
  open: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

export default function DraggableDialogConfirmation({
  open,
  handleAccept,
  handleClose,
}: DraggableDialogConfirmationProps) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: "move" }}>Save Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved edits. Are you sure you want to navigate off this
            page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAccept}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
