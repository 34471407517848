import Link from "@mui/material/Link";
import logoImage from "./img/logo.svg";
import logoText from "./img/text.svg";
import { styles } from "./styles";

const Logo = () => {
  return (
    <div style={styles.logoTextContainer}>
      <Link href="/">
        <div style={styles.logoContainer}>
          <img alt="Logo" src={logoImage}></img>
        </div>
        <div style={styles.textContainer}>
          <img alt="Linguasource" src={logoText}></img>
        </div>
      </Link>
    </div>
  );
};

export default Logo;
