import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { theme } from "./utils/CustomTheme";
import LanguagesList from "./views/Languages/LanguagesList";
import LanguageEdit from "./views/Languages/LanguageEdit";
import LanguageNew from "./views/Languages/LanguageNew";
import Home from "./views/Home/Home";
import LeftNav from "./components/LeftNav/LeftNav";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} className="App" disableGutters>
        <Router>
          <Grid container>
            <Grid item xs={2.1513}>
              <LeftNav />
            </Grid>
            <Grid
              item
              xs={9.8487}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Routes>
                <Route path="/languages" element={<LanguagesList />} />
                <Route path="/" element={<Home />} />
                <Route
                  path="/language/:languageId"
                  element={<LanguageEdit />}
                />
                <Route path="/language" element={<LanguageNew />} />
              </Routes>
            </Grid>
          </Grid>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
