export const initValues = {
  id: null,
  code: "",
  name: "",
  iso3: "",
  rolvId: "",
  glottoId: "",
  speakers: null,
  sensitivity: "",
  directorName: "",
  autonym: "",
  pseudonym: "",
  country: "",
  status: "",
  notes: [],
  isoAlternateNames: "",
  fcbhAlternateNames: "",
};
