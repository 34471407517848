import {
  BORDER_COLOR,
  MAIN_COLOR,
  theme,
  pxToRem,
} from "../../utils/CustomTheme";

export const styles = {
  searchResultTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(14),
    color: theme.palette.text.secondary,
  },
  newSearchButton: {
    padding: `${pxToRem(16)} ${pxToRem(34)} ${pxToRem(16)} ${pxToRem(32)}`,
    borderColor: MAIN_COLOR,
    gap: pxToRem(10),
    fontSize: pxToRem(14),
    fontWeight: 700,
    lineHeight: "100%",
  },
  containerLanguageList: {
    backgroundColor: theme.palette.background.default,
  },
  languageCard: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 4,
    padding: `${pxToRem(10)} ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(16)}`,
  },
  languageBorderTop: {
    backgroundColor: BORDER_COLOR,
    height: pxToRem(10),
    margin: `${pxToRem(-10)} ${pxToRem(-16)} ${pxToRem(0)} ${pxToRem(-16)}`,
  },
  languageCardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(18),
    fontWeight: 600,
    lineHeight: pxToRem(21.6),
    mb: pxToRem(16),
    color: theme.palette.primary.main,
  },
  languageCardButton: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: "100%",
    padding: `${pxToRem(12)} ${pxToRem(32)} ${pxToRem(12)} ${pxToRem(32)}`,
    mt: 2,
  },
};
