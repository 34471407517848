import { useState } from "react";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import Collapse from "@mui/material/Collapse";
import {
  LanguageIcon,
  LanguageSearchIcon,
  AgreementsIcon,
  MainModuleTab,
  SubModuleTab,
  SideTabs,
  SubSideTabs,
  AgreementSearchIcon,
  AgreementAddIcon,
} from "./styles";

const TAB_LANGUAGES = 1;
const SUB_TAB_EDIT_LANGUAGES = 11;
const TAB_AGREEMENTS = 2;
const SUB_TAB_EDIT_AGREEMENTS = 21;
const SUB_TAB_ADD_AGREEMENTS = 22;

const getCurrentTab = (locationPathname: string): number => {
  return getTabFromLocationPath(locationPathname) || TAB_LANGUAGES;
};

const getTabFromLocationPath = (locationPathname: string): number => {
  const pathPeerTab = [
    { tab: SUB_TAB_EDIT_LANGUAGES, path: "/languages" },
    { tab: SUB_TAB_EDIT_LANGUAGES, path: "/language/:languageId" },
    { tab: SUB_TAB_EDIT_AGREEMENTS, path: "/agreements" },
    { tab: SUB_TAB_ADD_AGREEMENTS, path: "/agreement" },
  ];

  let tabFound = 0;

  pathPeerTab.forEach(({ tab, path }) => {
    if (!!matchPath(path, locationPathname)) {
      tabFound = tab;
    }
  });

  return tabFound;
};

const isCollapsedLanguagesTab = (value: number): boolean => {
  return value === TAB_LANGUAGES || value === SUB_TAB_EDIT_LANGUAGES;
};

const isCollapsedAgreementsTab = (value: number): boolean => {
  return (
    value === TAB_AGREEMENTS ||
    value === SUB_TAB_EDIT_AGREEMENTS ||
    value === SUB_TAB_ADD_AGREEMENTS
  );
};

function SideMenu() {
  const location = useLocation();

  const [tabValue, setTabValue] = useState<number>(
    getCurrentTab(location.pathname)
  );

  const tabsHandleChange = (tabOption: number) => {
    setTabValue(tabOption);
  };

  const isTabSelected = (tabOption: number) => {
    return (
      tabValue === tabOption ||
      tabOption === getTabFromLocationPath(location.pathname)
    );
  };

  return (
    <SideTabs component="nav">
      <MainModuleTab
        label="Languages"
        icon={<LanguageIcon />}
        href="/"
        value={TAB_LANGUAGES}
        onChange={tabsHandleChange}
        selected={isTabSelected(TAB_LANGUAGES)}
        isCollapsed={isCollapsedLanguagesTab(tabValue)}
      />
      <Collapse in={isCollapsedLanguagesTab(tabValue)}>
        <SubSideTabs component="div">
          <SubModuleTab
            label="Search & Edit"
            icon={<LanguageSearchIcon />}
            href="/languages"
            isSubTab={true}
            value={SUB_TAB_EDIT_LANGUAGES}
            onChange={tabsHandleChange}
            selected={isTabSelected(SUB_TAB_EDIT_LANGUAGES)}
          />
        </SubSideTabs>
      </Collapse>
      <MainModuleTab
        label="Agreements"
        icon={<AgreementsIcon />}
        href="/"
        value={TAB_AGREEMENTS}
        onChange={tabsHandleChange}
        selected={isTabSelected(TAB_AGREEMENTS)}
        isCollapsed={isCollapsedAgreementsTab(tabValue)}
      />
      <Collapse in={isCollapsedAgreementsTab(tabValue)}>
        <SubSideTabs component="div">
          <SubModuleTab
            label="Search & Edit"
            icon={<AgreementSearchIcon />}
            href="/agreements"
            isSubTab={true}
            value={SUB_TAB_EDIT_AGREEMENTS}
            onChange={tabsHandleChange}
            selected={isTabSelected(SUB_TAB_EDIT_AGREEMENTS)}
          />
          <SubModuleTab
            label="Add An Agreement"
            icon={<AgreementAddIcon />}
            href="/agreement"
            isSubTab={true}
            value={SUB_TAB_ADD_AGREEMENTS}
            onChange={tabsHandleChange}
            selected={isTabSelected(SUB_TAB_ADD_AGREEMENTS)}
          />
        </SubSideTabs>
      </Collapse>
    </SideTabs>
  );
}

export default SideMenu;
