import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DraggableDialogConfirmation from "./DraggableDialogConfirmation";
import { styles } from "./styles";
import { getDateformatValues } from "../../utils/Helpers";

const formatUpdatedAtValue = (updatedAt: string): string => {
  const { day, mo, hour } = getDateformatValues(updatedAt);
  return `${day} ${mo} - ${hour}`;
};

export default function LanguageFormBreadcrumb({
  hasFormChanged,
  updatedAt,
}: {
  hasFormChanged: boolean;
  updatedAt: string;
}) {
  const navigate = useNavigate();

  const [openDialogConfirmation, setOpenDialogConfirmation] =
    useState<boolean>(false);

  const navigateGoBack = (): void => {
    navigate(-1);
  };

  const handleGoBack = (event: SyntheticEvent): void => {
    event.preventDefault();

    if (hasFormChanged) {
      setOpenDialogConfirmation(true);
      return;
    }

    navigateGoBack();
  };

  const closeOpenDialogConfirmation = (): void => {
    setOpenDialogConfirmation(false);
  };

  const breadcrumbLeft = [
    <Link
      component="button"
      href="#"
      underline="none"
      onClick={handleGoBack}
      sx={styles.linkGoToBack}
      key="breadcrumb-go-back"
    >
      <KeyboardArrowLeftIcon sx={styles.iconLinkGoToBack} />
      Back to Search Results
    </Link>,
  ];

  const breadcrumbRight = [
    <Typography sx={styles.lastSaveText} key="breadcrumb-updated-at">
      <SaveAltIcon sx={styles.iconLastSaveText}></SaveAltIcon> Last Save:{" "}
      {updatedAt ? formatUpdatedAtValue(updatedAt) : ""}
    </Typography>,
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={12}
        mt={1.125}
        mb={1.125}
      >
        <Breadcrumbs>{breadcrumbLeft}</Breadcrumbs>
        {updatedAt ? <Breadcrumbs>{breadcrumbRight}</Breadcrumbs> : ""}
      </Stack>
      <DraggableDialogConfirmation
        open={openDialogConfirmation}
        handleClose={closeOpenDialogConfirmation}
        handleAccept={navigateGoBack}
      />
    </>
  );
}
