import { SetStateAction } from "react";
import { FieldProps } from "formik";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import LanguageModel from "../../model/LanguageModel";
import { TextFastField } from "../../components/Form/TextFastField/TextFastField";
import { CountryField } from "../../components/Form/Autocomplete/CountryField";
import { DirectorField } from "../../components/Form/Autocomplete/DirectorField";
import { StatusField } from "../../components/Form/Autocomplete/StatusField";

export const TYPE_COUNTRY_FIELD_SELECT = "country";
export const TYPE_DIRECTOR_FIELD_SELECT = "director";
export const TYPE_STATUS_FIELD_SELECT = "status";

export const renderFieldSelect = (
  labelField: string,
  initValue: string,
  options: { value: string; label: string }[],
  fieldProps: FieldProps
) => {
  const {
    field,
    form: { touched: fieldTouched, errors: fieldErrors },
  } = fieldProps;

  const hasError = !!fieldTouched[field.name] && !!fieldErrors[field.name];

  return (
    <Box>
      <InputLabel
        htmlFor={`language-${field.name}`}
        sx={{ fontSize: 12, fontWeight: 500 }}
      >
        {labelField}
      </InputLabel>
      <TextField
        select
        id={`language-${field.name}`}
        name={field.name}
        value={field.value || initValue}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={hasError}
        fullWidth
        helperText={hasError && `${fieldErrors[field.name]}`}
      >
        {options.map((option: { label: string; value: string }) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export const renderAutocompleteFieldSelect = (
  type: string,
  labelField: string,
  initValue: string,
  fieldProps: FieldProps,
  setValues: (
    values: SetStateAction<LanguageModel>,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const {
    field,
    form: { touched: fieldTouched, errors: fieldErrors },
  } = fieldProps;

  const hasError = !!fieldTouched[field.name] && !!fieldErrors[field.name];

  let selectField = null;

  const propsField = {
    id: `language-${field.name}`,
    name: field.name,
    value: field.value || initValue,
    onChange: field.onChange,
    onBlur: field.onBlur,
    error: hasError,
    setValues: setValues,
  };

  switch (type) {
    case TYPE_COUNTRY_FIELD_SELECT:
      selectField = <CountryField {...propsField} />;
      break;
    case TYPE_DIRECTOR_FIELD_SELECT:
      selectField = <DirectorField {...propsField} />;
      break;
    case TYPE_STATUS_FIELD_SELECT:
      selectField = <StatusField {...propsField} />;
      break;

    default:
      break;
  }
  return (
    <Box>
      <InputLabel
        htmlFor={`language-${field.name}`}
        sx={{ fontSize: 12, fontWeight: 500 }}
      >
        {labelField}
      </InputLabel>
      {selectField}
    </Box>
  );
};

export const renderFieldText = (labelField: string, fieldProps: FieldProps) => {
  const {
    field,
    form: { errors: fieldErrors },
  } = fieldProps;

  const hasError = !!fieldErrors[field.name];

  return (
    <Box>
      <InputLabel
        htmlFor={`language-${field.name}`}
        sx={{ fontSize: 12, fontWeight: 500 }}
      >
        {labelField}
      </InputLabel>
      <TextFastField
        id={`language-${field.name}`}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        error={hasError}
        fullWidth
        helperText={hasError && `${fieldErrors[field.name]}`}
      />
    </Box>
  );
};

export const renderFormikField = (labelField: string) => {
  return (fieldProps: FieldProps) => renderFieldText(labelField, fieldProps);
};
