import { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { Form, Field, FormikProps, FieldProps } from "formik";
import { styles } from "./styles";
import { TextFastField } from "../../components/Form/TextFastField/TextFastField";
import { useSaveNote } from "../../hooks/useSaveNote";
import NoteModel from "../../model/NoteModel";
import { getDateformatValues } from "../../utils/Helpers";

const formatEntryDate = (date: string): string => {
  const { day, mo, hour, year } = getDateformatValues(date);
  return `${mo} ${day}, ${year} ${hour}`;
};

interface InnerFormmProps {
  isNew: boolean;
  languageId: number;
  onClose: () => void;
  onSave: () => void;
}

const NoteForm = (noteProps: InnerFormmProps & FormikProps<NoteModel>) => {
  const {
    languageId,
    onClose,
    onSave,
    isNew,
    handleSubmit,
    values,
    isValid,
    validateForm,
  } = noteProps;

  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const closeSuccessMessage = () => setSuccessMessage(false);

  const onSuccesQuery = (response: { data: { id: number } }) => {
    const { data: noteFetched } = response;

    if (noteFetched.id > 0) {
      setSuccessMessage(true);
      onSave();
    }
  };

  const onErrorQuery = (err: Error): void => {
    console.log(err);
  };

  const { isLoadingNote, isFetching, isError, saveNote } = useSaveNote({
    id: 0,
    languageId: languageId,
    payload: values,
    onSuccess: onSuccesQuery,
    onError: onErrorQuery,
    isNew,
  });

  const handleOnClick = async () => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length === 0) {
      saveNote();
    }
  };

  return (
    <>
      <DialogContent dividers>
        <Form onSubmit={handleSubmit}>
          <Stack>
            {isError && (
              <Alert severity="error">
                This is an error alert — try again!
              </Alert>
            )}
            {successMessage && (
              <Alert onClose={closeSuccessMessage} severity="success">
                Language has been {isNew ? "cretaed" : "updated"}!
              </Alert>
            )}
            <Typography sx={styles.noteAuthor}>Author</Typography>
            <Typography sx={styles.noteDate}>
              {formatEntryDate("now")}
            </Typography>
            <Box mt={1}>
              <Field name="note">
                {(fieldProps: FieldProps) => {
                  const {
                    field,
                    form: { errors: fieldErrors },
                  } = fieldProps;

                  const hasError = !!fieldErrors[field.name];

                  return (
                    <TextFastField
                      id={`note-${field.name}`}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={hasError}
                      fullWidth
                      multiline
                      rows={10}
                      helperText={hasError && `${fieldErrors[field.name]}`}
                    />
                  );
                }}
              </Field>
            </Box>
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button
          sx={styles.notesCancelButton}
          color="secondary"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={styles.noteAddButton}
          variant="contained"
          autoFocus
          onClick={handleOnClick}
          loading={isLoadingNote || isFetching}
          disabled={!isValid}
          startIcon={<EditIcon />}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default NoteForm;
