import http from "../utils/HttpCommon";

interface StatusType {
  name: string;
}

const search = async (text: string) => {
  const response = await http.get<StatusType[]>(`/status/search/${text}`);
  const { data: dataFetched } = response;
  return dataFetched;
};

const all = async () => {
  const response = await http.get<{ data: StatusType[] }>(`/status/list`);
  const { data: dataFetched } = response;
  return dataFetched;
};

const StatusService = {
  search,
  all,
};

export default StatusService;
