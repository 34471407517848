import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styles } from "./styles";
import {
  OPT_SEARCH_ALL,
  OPT_SEARCH_LANGUAGE,
  OPT_SEARCH_TRANSLATION,
  OPT_SEARCH_BIBLE,
  OPT_SEARCH_MEDIAID,
} from "../../utils/SearchingOptions";

interface SearchStackRadiosProps {
  optionChecked?: string;
  handleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchStackRadios = ({
  optionChecked = OPT_SEARCH_ALL,
  handleClick: handleCheckClick,
}: SearchStackRadiosProps) => {
  return (
    <Stack direction={"row"} spacing={2} alignItems="center">
      <Typography sx={styles.textResults}>
        Search Results | Filter By
      </Typography>
      <FormControl>
        <RadioGroup row name="row-radio-filters-group">
          <FormControlLabel
            value={OPT_SEARCH_ALL}
            control={
              <Radio
                checked={OPT_SEARCH_ALL === optionChecked}
                onChange={handleCheckClick}
                sx={styles.radio}
              />
            }
            label={OPT_SEARCH_ALL}
            sx={
              OPT_SEARCH_ALL === optionChecked
                ? styles.radioLabelChecked
                : styles.radioLabel
            }
          />
          <FormControlLabel
            value={OPT_SEARCH_LANGUAGE}
            control={
              <Radio
                checked={OPT_SEARCH_LANGUAGE === optionChecked}
                onChange={handleCheckClick}
                sx={styles.radio}
              />
            }
            label={OPT_SEARCH_LANGUAGE}
            sx={
              OPT_SEARCH_LANGUAGE === optionChecked
                ? styles.radioLabelChecked
                : styles.radioLabel
            }
          />
          <FormControlLabel
            value={OPT_SEARCH_TRANSLATION}
            control={
              <Radio
                checked={OPT_SEARCH_TRANSLATION === optionChecked}
                onChange={handleCheckClick}
                sx={styles.radio}
              />
            }
            label={OPT_SEARCH_TRANSLATION}
            sx={
              OPT_SEARCH_TRANSLATION === optionChecked
                ? styles.radioLabelChecked
                : styles.radioLabel
            }
          />
          <FormControlLabel
            value={OPT_SEARCH_BIBLE}
            control={
              <Radio
                checked={OPT_SEARCH_BIBLE === optionChecked}
                onChange={handleCheckClick}
                sx={styles.radio}
              />
            }
            label={OPT_SEARCH_BIBLE}
            sx={
              OPT_SEARCH_BIBLE === optionChecked
                ? styles.radioLabelChecked
                : styles.radioLabel
            }
          />
          <FormControlLabel
            value={OPT_SEARCH_MEDIAID}
            control={
              <Radio
                checked={OPT_SEARCH_MEDIAID === optionChecked}
                onChange={handleCheckClick}
                sx={styles.radio}
              />
            }
            label={OPT_SEARCH_MEDIAID}
            sx={
              OPT_SEARCH_MEDIAID === optionChecked
                ? styles.radioLabelChecked
                : styles.radioLabel
            }
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default SearchStackRadios;
