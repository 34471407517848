import http from "../utils/HttpCommon";
import NoteModel from "../model/NoteModel";
import MetaPagination from "../types/MetaPagination";
import NoteRequestParameters from "../types/NoteRequestParameters";

interface NoteModelResponse extends NoteModel {
  entry_date: string;
}

type NoteModelType = NoteModel & NoteModelResponse[];

const get = async (languageId: number, noteId: number) => {
  const response = await http.get(`/language/${languageId}/note/${noteId}`);

  const { data: dataFetched } = response;
  const { data: note } = dataFetched;

  return {
    data: {
      ...note,
      entryDate: note?.entry_date,
    },
  };
};

const list = async (languageId: number, params: NoteRequestParameters) => {
  const response = await http.get<{
    data: NoteModelType;
    meta: MetaPagination;
  }>(`/language/${languageId}/note/list`, { params });

  return {
    data: response.data.data.map(
      (note: NoteModel & NoteModelResponse): NoteModel => {
        return {
          ...note,
          entryDate: note?.entry_date,
        };
      }
    ),
    meta: response.data.meta,
  };
};

const put = async (id: number, payload: NoteModel) => {
  const response = await http.put(`/note/${id}`, payload);
  const { data: updatedData } = response;
  return updatedData;
};

const post = async (languageId: number, payload: NoteModel) => {
  const response = await http.post(`/language/${languageId}/note`, payload);
  const { data: updatedData } = response;
  return updatedData;
};

const NoteService = {
  list,
  get,
  put,
  post,
};

export default NoteService;
