import { useState, ChangeEvent } from "react";
import {
  Pagination,
  Stack,
  Typography,
  Divider,
  Paper,
  Alert,
} from "@mui/material";
import { styles } from "./styles";
import NoteSkeleton from "./NoteSkeleton";
import NoteModel from "../../model/NoteModel";
import MetaPagination from "../../types/MetaPagination";
import { useGetNoteListQuery } from "../../hooks/useGetNoteListQuery";
import { getDateformatValues } from "../../utils/Helpers";

export interface NoteListProps {
  languageId: number;
}

const limit = 50;

const formatEntryDate = (date: string): string => {
  const { day, mo, hour, year } = getDateformatValues(date);
  return `${mo} ${day}, ${year} ${hour}`;
};

export default function NoteList(props: NoteListProps) {
  const { languageId } = props;

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [notes, setNotes] = useState<NoteModel[]>([]);

  const handlePageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onSuccesQuery = (response: {
    data: NoteModel[];
    meta: MetaPagination;
  }) => {
    const { data: noteList, meta: metaData } = response;

    setNotes(noteList);
    setCount(metaData.pagination.count);
    setPerPage(metaData.pagination.per_page);
    setTotalPages(metaData.pagination.total_pages);
  };

  const onErrorQuery = (err: Error): void => {
    setNotes([]);
    setCount(0);
    setPerPage(0);
    console.log(err);
  };

  const { isLoadingNotes, isFetching, isError } = useGetNoteListQuery({
    languageId,
    page,
    limit,
    onSuccess: onSuccesQuery,
    onError: onErrorQuery,
  });

  if (isLoadingNotes || isFetching || !languageId) {
    return <NoteSkeleton />;
  }

  return (
    <>
      {isError ? (
        <Alert severity="error">This is an error alert — try again!</Alert>
      ) : (
        ""
      )}
      <Stack spacing={3}>
        {notes.map((note: NoteModel) => {
          return (
            <Paper
              sx={styles.notePaper}
              elevation={0}
              key={`language-note-${note.id}`}
            >
              <Typography sx={styles.noteDescription}>{note.note}</Typography>
              <Divider />
              <Stack mt={1} direction="row" spacing={1}>
                <Typography sx={styles.noteAuthor}>{note.author}</Typography>
                <Typography sx={styles.noteDate}>
                  {note.entryDate ? formatEntryDate(note.entryDate) : ""}
                </Typography>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
      {count > 0 && count > perPage ? (
        <Stack mt={1} spacing={2} alignItems="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      ) : (
        ""
      )}
    </>
  );
}
