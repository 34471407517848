import http from "../utils/HttpCommon";
import LanguageModel from "../model/LanguageModel";
import LanguageRequestParameters from "../types/LanguageRequestParameters";
import MetaPagination from "../types/MetaPagination";

const findAll = async (params: LanguageRequestParameters) => {
  const response = await http.get<{
    data: LanguageModel[];
    meta: MetaPagination;
  }>("/language", { params });
  return response.data;
};

const get = async (id: number) => {
  const response = await http.get(`/language/${id}`);

  const { data: dataFetched } = response;
  const { data: language } = dataFetched;

  return {
    data: {
      ...language,
      country: language?.country?.name,
      status: language?.status?.name,
      updatedAt: language?.updated_at,
      fcbhAlternateNames: language?.fcbh_alternate_names?.join(","),
      isoAlternateNames: language?.iso_alternate_names?.join(","),
    },
  };
};

const put = async (id: number, payload: LanguageModel) => {
  const response = await http.put(`/language/${id}`, payload);
  const { data: updatedData } = response;
  return updatedData;
};

const post = async (payload: LanguageModel) => {
  const response = await http.post(`/language`, payload);
  const { data: updatedData } = response;
  return updatedData;
};

const LanguageService = {
  findAll,
  get,
  put,
  post,
};

export default LanguageService;
