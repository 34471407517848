import { useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import LanguageSkeleton from "./LanguageSkeleton";
import LanguageForm from "../../components/Language/LanguageForm";
import { useGetLanguage } from "../../hooks/useGetLanguage";
import LanguageModel from "../../model/LanguageModel";
import { initValues } from "../../utils/LanguageObjectEmpty";

export default function LanguageEdit() {
  const params = useParams();
  const [language, setLanguage] = useState<LanguageModel>(initValues);

  const onSuccesQuery = (response: { data: LanguageModel }) => {
    const { data: languageFetched } = response;
    setLanguage(languageFetched);
  };

  const onErrorQuery = (err: Error): void => {
    setLanguage(initValues);
    console.log(err);
  };

  const { isLoadingLanguage, isFetching, isError } = useGetLanguage({
    id: parseInt(params.languageId || ""),
    onSuccess: onSuccesQuery,
    onError: onErrorQuery,
  });

  if (isLoadingLanguage || isFetching || !language.id) {
    return <LanguageSkeleton />;
  }

  return !isError ? (
    <LanguageForm language={language} isNew={false} />
  ) : (
    <Alert severity="error">This is an error alert — try again!</Alert>
  );
}
