export const sensitivities = [
  {
    value: "individual",
    label: "Individual",
  },
  {
    value: "fcbh-variety",
    label: "FCBH Variety",
  },
  {
    value: "macrolanguage",
    label: "Macrolanguage",
  },
  {
    value: "language-variety",
    label: "Language Variety",
  },
  {
    value: "high",
    label: "High",
  },
];
