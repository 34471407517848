import http from "../utils/HttpCommon";

export interface CountryType {
  name: string;
}

const search = async (text: string) => {
  const response = await http.get<CountryType[]>(`/country/search/${text}`);

  const { data: dataFetched } = response;

  return dataFetched;
};

const all = async () => {
  const response = await http.get<{ data: CountryType[] }>(`/countries`);

  const { data: dataFetched } = response;

  return dataFetched;
};

const CountryService = {
  search,
  all,
};

export default CountryService;
