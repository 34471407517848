import {
  Card,
  Stack,
  CardContent,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styles } from "./styles";
import LanguageModel from "../../model/LanguageModel";

interface LanguageCardListProps {
  list: LanguageModel[];
}

const LanguageCardList = ({ list: languages }: LanguageCardListProps) => {
  const navigate = useNavigate();

  const handleOnClickEditLanguage = (
    languageId: number | null
  ): (() => void) => {
    return (): void => {
      if (languageId) {
        navigate(`/language/${languageId}`);
      }
    };
  };

  return (
    <div>
      {languages ? (
        languages.map((language, index) => (
          <Card
            key={`language-${index}-${language.name}`}
            sx={styles.languageCard}
          >
            {/* <Box component="div" sx={styles.languageBorderTop}></Box> */}
            <CardContent sx={{ pt: 1.5 }}>
              <Typography
                sx={styles.languageCardTitle}
                variant="h5"
                component="div"
                gutterBottom
              >
                {language.name}
              </Typography>
              <Stack direction={"row"}>
                <Typography sx={{ mb: 1.75 }} color="text.secondary">
                  ID:
                </Typography>
                <Typography sx={{ mb: 1.75, mr: 1.75 }}>
                  {language.code}
                </Typography>
                <Typography sx={{ mb: 1.75 }} color="text.secondary">
                  ISO3:
                </Typography>
                <Typography sx={{ mb: 1.75, mr: 1.75 }}>
                  {language.iso3}
                </Typography>
                <Typography sx={{ mb: 1.75 }} color="text.secondary">
                  Country:
                </Typography>
                <Typography sx={{ mb: 1.75 }}>{language.country}</Typography>
              </Stack>
              <Divider />
              <Button
                variant="contained"
                size="small"
                sx={styles.languageCardButton}
                onClick={handleOnClickEditLanguage(language.id)}
              >
                Edit Language
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography
          sx={styles.languageCardTitle}
          variant="h5"
          component="div"
          gutterBottom
        >
          No Results
        </Typography>
      )}
    </div>
  );
};

export default LanguageCardList;
