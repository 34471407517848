import http from "../utils/HttpCommon";

interface DirectorType {
  name: string;
}

const search = async (text: string) => {
  const response = await http.get<DirectorType[]>(`/director/search/${text}`);

  const { data: dataFetched } = response;

  return dataFetched;
};

const all = async () => {
  const response = await http.get<{ data: DirectorType[] }>(`/directors`);
  const { data: dataFetched } = response;
  return dataFetched;
};

const DirectorService = {
  search,
  all,
};

export default DirectorService;
