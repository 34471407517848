export const getDateformatValues = (
  inputDate: string = "now"
): { mo: string; day: string; hour: string; year: string } => {
  const date =
    inputDate !== "now" && inputDate !== "" ? new Date(inputDate) : new Date();
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  const hour = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  return {
    mo,
    day,
    hour,
    year,
  };
};
