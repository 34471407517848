import { useState } from "react";
import { FormikProps, Form, Field, FieldProps } from "formik";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { styles } from "./styles";
import {
  TYPE_COUNTRY_FIELD_SELECT,
  TYPE_DIRECTOR_FIELD_SELECT,
  TYPE_STATUS_FIELD_SELECT,
  renderFieldSelect,
  renderAutocompleteFieldSelect,
  renderFieldText,
  renderFormikField,
} from "./Helpers";
import LanguageFormBreadcrumb from "./LanguageFormBreadcrumb";
import LanguageModel from "../../model/LanguageModel";
import { sensitivities } from "../../utils/Sensitivities";
import { useSaveLanguage } from "../../hooks/useSaveLanguage";
import NoteDialog from "../Notes/NoteDialog";

interface InnerFormmProps {
  isNew: boolean;
}

export default function InnerForm(
  props: InnerFormmProps & FormikProps<LanguageModel>
) {
  const {
    handleSubmit,
    values,
    isNew,
    isValid,
    dirty,
    validateForm,
    setValues,
  } = props;

  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [openNoteList, setOpenNoteList] = useState<boolean>(false);

  const closeSuccessMessage = () => setSuccessMessage(false);

  const isNewLanguage = (): boolean => {
    return isNew && !(values?.id && values?.id > 0);
  };

  const updateLanguageIdForNew = (newLanguageId: number): void => {
    if (isNewLanguage()) {
      setValues({
        ...values,
        id: newLanguageId,
      });
    }
  };

  const onSuccesQuery = (response: { data: { id: number } }) => {
    const { data: languageFetched } = response;

    if (languageFetched.id > 0) {
      setSuccessMessage(true);
      updateLanguageIdForNew(languageFetched.id);
    }
  };

  const onErrorQuery = (err: Error): void => {
    console.log(err);
  };

  const { isLoadingLanguage, isFetching, isError, saveLanguage } =
    useSaveLanguage({
      id: values.id || 0,
      payload: values,
      onSuccess: onSuccesQuery,
      onError: onErrorQuery,
      isNew: isNewLanguage(),
    });

  const handleOnClick = async () => {
    const formErrors = await validateForm();

    if (Object.keys(formErrors).length === 0) {
      saveLanguage();
    }
  };

  const handleNotesDialog = () => {
    setOpenNoteList(true);
  };

  const closeNotesDialog = () => {
    setOpenNoteList(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        {successMessage && (
          <Alert onClose={closeSuccessMessage} severity="success">
            Language has been {isNew ? "cretaed" : "updated"}!
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={(theme) => ({ backgroundColor: theme.palette.common.white })}
          >
            <Box sx={{ pl: 6, pr: 9 }}>
              <LanguageFormBreadcrumb
                hasFormChanged={dirty}
                updatedAt={values.updatedAt || ""}
              />
              {isError && (
                <Alert severity="error">
                  This is an error alert — try again!
                </Alert>
              )}
              <Divider variant="fullWidth" sx={{ mr: -9, ml: -6, mb: 1.5 }} />
              <Grid container item xs={12} spacing={4}>
                <Grid item sm={7}>
                  <Field name="name">
                    {renderFormikField("Language Name")}
                  </Field>
                </Grid>
                <Grid item sm={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={styles.notesButton}
                    startIcon={<StickyNote2Icon sx={styles.iconNotes} />}
                    onClick={handleNotesDialog}
                  >
                    Notes
                  </Button>
                  {openNoteList && values.id ? (
                    <NoteDialog
                      open
                      languageId={values.id}
                      languageName={values.name || ""}
                      handleClose={closeNotesDialog}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item sm={3} sx={{ textAlign: "right" }}>
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    sx={styles.saveButton}
                    loading={isLoadingLanguage || isFetching}
                    onClick={handleOnClick}
                    disabled={!isValid}
                  >
                    Save Changes
                  </LoadingButton>
                </Grid>
              </Grid>
              <Divider variant="fullWidth" sx={{ mr: -9, ml: -6, mt: 2.5 }} />
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sx={styles.detailsBackground}>
              <Box sx={{ pl: 6, pr: 9 }}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: "14px" }}>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: "8px",
                        height: "8px",
                        borderRadius: "100px",
                        backgroundColor: "#AA182C",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                        mr: "8px",
                      }}
                      component="span"
                    />
                    Language Details
                  </Typography>
                </Grid>
                <Grid item container xs={12} spacing={4} sx={{ pb: 2 }}>
                  <Grid item sm={2}>
                    <Field name="code">{renderFormikField("Langage ID")}</Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="sensitivity">
                      {(fieldProps: FieldProps) =>
                        renderFieldSelect(
                          "Category",
                          "individual",
                          sensitivities,
                          fieldProps
                        )
                      }
                    </Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="iso3">{renderFormikField("ISO")}</Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="rolvId">{renderFormikField("ROLV")}</Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="country">
                      {(fieldProps: FieldProps) =>
                        renderAutocompleteFieldSelect(
                          TYPE_COUNTRY_FIELD_SELECT,
                          "Country",
                          "United States",
                          fieldProps,
                          setValues
                        )
                      }
                    </Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="speakers">
                      {renderFormikField("Speakers")}
                    </Field>
                  </Grid>
                </Grid>

                <Grid container item spacing={4} sx={{ pb: 2 }}>
                  <Grid item sm={4}>
                    <Field name="directorName">
                      {(fieldProps: FieldProps) =>
                        renderAutocompleteFieldSelect(
                          TYPE_DIRECTOR_FIELD_SELECT,
                          "Area Director",
                          "",
                          fieldProps,
                          setValues
                        )
                      }
                    </Field>
                  </Grid>
                  <Grid item sm={4}>
                    <Field name="status">
                      {(fieldProps: FieldProps) =>
                        renderAutocompleteFieldSelect(
                          TYPE_STATUS_FIELD_SELECT,
                          "Language Status",
                          "",
                          fieldProps,
                          setValues
                        )
                      }
                    </Field>
                  </Grid>
                </Grid>

                <Grid container item spacing={4} sx={{ pb: 2 }}>
                  <Grid item sm={2}>
                    <Field name="isoLanguageName">
                      {renderFormikField("ISO Language Name")}
                    </Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="autonym">{renderFormikField("Autonym")}</Field>
                  </Grid>
                  <Grid item sm={2}>
                    <Field name="pseudonym">
                      {renderFormikField("FCBH-Pseudonym")}
                    </Field>
                  </Grid>
                </Grid>

                <Grid container item spacing={2} sx={{ pb: 2 }}>
                  <Grid item sm={12}>
                    <Field name="isoAlternateNames">
                      {(fieldProps: FieldProps) => (
                        <Box>
                          {renderFieldText("ISO Alternate Names", fieldProps)}
                          <InputLabel sx={{ fontSize: 12 }}>
                            Use comma separated values. Example: Name 1, Name 2,
                            Name 3, and so on.
                          </InputLabel>
                        </Box>
                      )}
                    </Field>
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="fcbhAlternateNames">
                      {(fieldProps: FieldProps) => (
                        <Box>
                          {renderFieldText("FCBH Alternate Names", fieldProps)}
                          <InputLabel sx={{ fontSize: 12 }}>
                            Use comma separated values. Example: Name 1, Name 2,
                            Name 3, and so on.
                          </InputLabel>
                        </Box>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
}
