import { theme, pxToRem } from "../../utils/CustomTheme";

const buttonBase = {
  padding: `${pxToRem(11)} ${pxToRem(24)}`,
  fontSize: pxToRem(14),
  height: pxToRem(48),
  mt: pxToRem(16),
};

export const styles = {
  saveButton: {
    ...buttonBase,
    fontWeight: 600,
    lineHeight: pxToRem(26),
  },
  notesButton: {
    ...buttonBase,
    color: theme.palette.text.primary,
    backgroundColor: "#FAFBFD",
  },
  detailsBackground: {
    pt: pxToRem(16),
  },
  linkGoToBack: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(11),
    color: theme.palette.text.secondary,
    fontWeight: "500",
    letterSpacing: pxToRem(0.1),
  },
  iconLinkGoToBack: {
    width: pxToRem(16),
    height: pxToRem(16),
    mr: pxToRem(2),
    color: theme.palette.text.secondary,
    opacity: 0.5,
  },
  iconLastSaveText: {
    width: pxToRem(16),
    height: pxToRem(16),
    mr: pxToRem(2),
    color: theme.palette.text.secondary,
  },
  iconNotes: {
    color: theme.palette.primary.main,
  },
  lastSaveText: {
    display: "flex",
    right: 0,
    alignItems: "center",
    fontSize: pxToRem(11),
    color: theme.palette.text.secondary,
    fontWeight: "500",
    letterSpacing: pxToRem(0.1),
  },
};
