import { useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import NoteModel from "../model/NoteModel";
import MetaPagination from "../types/MetaPagination";
import NoteService from "../services/NoteService";
import NoteRequestParameters from "../types/NoteRequestParameters";

const getRequestParams = (
  pageInput: number,
  limitInput: number
): NoteRequestParameters => {
  let params: NoteRequestParameters = {};

  if (pageInput) {
    params["page"] = pageInput - 1;
  }

  if (limitInput) {
    params["limit"] = limitInput;
  }

  return params;
};

interface UseGetNoteListQueryProps {
  languageId: number;
  page: number;
  limit: number;
  onSuccess: (response: { data: NoteModel[]; meta: MetaPagination }) => void;
  onError: (err: Error) => void;
}

export const useGetNoteListQuery = ({
  languageId,
  page,
  limit,
  onSuccess,
  onError,
}: UseGetNoteListQueryProps) => {
  const {
    isLoading: isLoadingNotes,
    isFetching,
    isError,
    refetch: getNotesByLanguageId,
  } = useQuery<{ data: NoteModel[]; meta: MetaPagination }, Error>(
    "query-search-notes-by-language",
    async () => {
      const params = getRequestParams(page, limit);
      return NoteService.list(languageId, params);
    },
    {
      retry: 1,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  const getAllNotesByLanguageId = useCallback((): void => {
    getNotesByLanguageId();
  }, [getNotesByLanguageId]);

  useEffect(() => {
    getAllNotesByLanguageId();
  }, [page, getAllNotesByLanguageId]);

  return {
    isLoadingNotes,
    isFetching,
    isError,
    getAllNotesByLanguageId,
  };
};
