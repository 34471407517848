import AutocompleteCustom, {
  BaseAutocompleteProps,
} from "./AutocompleteCustom";
import StatusService from "../../../services/StatusService";

interface StatusFieldProps extends BaseAutocompleteProps {}

export const StatusField = (props: StatusFieldProps) => {
  const fetchData = async (): Promise<{ name: string }[]> => {
    const { data: resultCountries } = await StatusService.all();
    return resultCountries;
  };

  return <AutocompleteCustom {...props} fetchData={fetchData} />;
};

export default StatusField;
