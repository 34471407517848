import { useQuery } from "react-query";
import NoteModel from "../model/NoteModel";
import NoteService from "../services/NoteService";

interface UseSaveNoteProps {
  id: number;
  languageId: number;
  payload: NoteModel;
  isNew: boolean;
  onSuccess: (response: { data: { id: number } }) => void;
  onError: (err: Error) => void;
}

export const useSaveNote = ({
  id,
  languageId,
  payload,
  onSuccess,
  onError,
  isNew = false,
}: UseSaveNoteProps) => {
  const {
    isLoading: isLoadingNote,
    isFetching,
    isError,
    refetch,
  } = useQuery<{ data: { id: number } }, Error>(
    "query-save-note",
    async () => {
      if (isNew) {
        return NoteService.post(languageId, payload);
      }

      return NoteService.post(id, payload);
    },
    {
      retry: 1,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  return {
    isLoadingNote,
    isFetching,
    isError,
    saveNote: refetch,
  };
};
