import { theme, pxToRem, BORDER_COLOR } from "../../utils/CustomTheme";

const buttonBase = {
  fontSize: pxToRem(13),
  fontWeight: 500,
  lineHeight: pxToRem(24),
};

export const styles = {
  dialog: {
    "& .MuiDialogContent-root": {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiPaper-root": {
      maxWidth: "500px",
    },
  },
  dialogActions: {
    padding: `${pxToRem(16)} ${pxToRem(32)}`,
  },
  dialogTitle: {
    padding: `${pxToRem(20)} ${pxToRem(32)}`,
  },
  dialogTitleIconButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.primary.main,
  },
  noteDialogTitle: {
    fontSize: pxToRem(18),
    lineHeight: pxToRem(21.6),
    fontWeight: 600,
  },
  noteDialogSubTitle: {
    fontSize: pxToRem(13),
    lineHeight: pxToRem(16.9),
    fontWeight: 500,
    color: theme.palette.text.secondary,
    mt: pxToRem(4),
  },
  noteAddButton: {
    ...buttonBase,
  },
  notesCancelButton: {
    ...buttonBase,
    color: theme.palette.text.primary,
    backgroundColor: "#FAFBFD",
  },
  notePaper: {
    padding: `${pxToRem(24)} ${pxToRem(24)} ${pxToRem(16)}`,
    border: `${pxToRem(1)} solid ${BORDER_COLOR}`,
  },
  noteDescription: {
    fontSize: pxToRem(15),
    mb: 1,
  },
  noteAuthor: {
    fontSize: pxToRem(13),
    fontWeight: 500,
    textTransform: "capitalize",
  },
  noteDate: {
    fontSize: pxToRem(13),
    fontWeight: 500,
    color: "#89929C",
  },
};
