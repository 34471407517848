import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { StandardTextFieldProps } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

const INPUT_DELAY = 300;

export const TextFastField = (props: StandardTextFieldProps) => {
  const [innerValue, setInnerValue] = useState<string | number | unknown>("");
  const { value, onChange } = props;

  useEffect(() => {
    if (value || value === 0) {
      setInnerValue(value);
    } else {
      setInnerValue("");
    }
  }, [value]);

  const debounced = useDebouncedCallback((event) => {
    if (onChange) {
      onChange(event);
    }
  }, INPUT_DELAY);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const newValue = event.currentTarget.value;
      setInnerValue(newValue);
      debounced(event);
    },
    [debounced]
  );

  return (
    <TextField
      {...props}
      value={innerValue}
      onChange={handleOnChange}
      size="small"
      variant="outlined"
    />
  );
};

export default TextFastField;
