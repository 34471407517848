export const styles = {
  logoTextContainer: {
    width: 168,
    height: 32,
    position: "relative" as "relative",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  logoContainer: {
    position: "absolute" as "absolute",
    bottom: 0,
    right: "80.76%",
    left: 0,
    top: 0,
  },
  textContainer: {
    position: "absolute" as "absolute",
    bottom: "30.89%",
    right: 0,
    left: "23.8%",
    top: "30.89%",
  },
};
