import { withFormik, FormikErrors } from "formik";
import Box from "@mui/material/Box";
import LanguageModel from "../../model/LanguageModel";
import InnerForm from "./InnerForm";

interface LanguageFormProps {
  sx?: Array<object | boolean> | object;
  language: LanguageModel;
  isNew: boolean;
}

export default function LanguageForm({
  sx,
  language,
  isNew = false,
}: LanguageFormProps) {
  const LanguageFormikForm = withFormik<LanguageFormProps, LanguageModel>({
    mapPropsToValues: () => {
      return {
        ...language,
      };
    },
    validate: (values: LanguageModel) => {
      const errors: FormikErrors<LanguageModel> = {};

      if (!values.code) {
        errors.code = "Required";
      }

      if (!values.name) {
        errors.name = "Required";
      }

      return errors;
    },
    handleSubmit: () => {
      /* TODO document why this method 'handleSubmit' is empty */
    },
  })(InnerForm);

  return (
    <Box sx={sx}>
      <LanguageFormikForm language={language} isNew={isNew} />
    </Box>
  );
}
