import AutocompleteCustom, {
  BaseAutocompleteProps,
} from "./AutocompleteCustom";
import DirectorService from "../../../services/DirectorService";

interface DirectorFieldProps extends BaseAutocompleteProps {}

export const DirectorField = (props: DirectorFieldProps) => {
  const fetchData = async (): Promise<{ name: string }[]> => {
    const { data: resultCountries } = await DirectorService.all();
    return resultCountries;
  };

  return <AutocompleteCustom {...props} fetchData={fetchData} />;
};

export default DirectorField;
