import { BORDER_COLOR, pxToRem, theme } from "../../utils/CustomTheme";

export const styles = {
  homeTitle: {
    fontWeight: 300,
    fontSize: pxToRem(42),
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: theme.spacing(33.75),
  },
  searchField: {
    "& .MuiFormLabel-root": {
      fontWeight: 500,
    },
  },
  searchButton: {
    padding: `${pxToRem(16)} ${pxToRem(52)}`,
    gap: pxToRem(10),
    lineHeight: "100%",
    fontSize: pxToRem(16),
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
  newButton: {
    padding: `${pxToRem(12)} ${pxToRem(17.5)}`,
    gap: pxToRem(10),
    borderColor: BORDER_COLOR,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    fontSize: pxToRem(14),
    lineHeight: "100%",
  },
  pageDivider: {
    marginTop: theme.spacing(33.75),
    borderColor: BORDER_COLOR,
  },
};
