import ListItem from "@mui/material/ListItem";
import { LinkTabProps } from "./ModuleTab";

export const LinkTab = (props: LinkTabProps) => {
  const {
    isSubTab = false,
    className,
    icon,
    href,
    label,
    value,
    onChange,
  } = props;
  return (
    <ListItem
      className={className}
      href={href}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onChange && value && onChange(value);

        if (!isSubTab) {
          event.preventDefault();
        }
      }}
    >
      {icon} {label}
    </ListItem>
  );
};

export default LinkTab;
