import { useNavigate } from "react-router-dom";
import {
  LeftNav1,
  SideMenuTop,
  LogoFrame,
  LogoLinguaSource1,
  SideMenu1,
  SideMenuBottom,
  Button2,
  Frame51,
  Account,
  SignOut,
  Rectangle22,
} from "./styles";

function LeftNav() {
  const navigate = useNavigate();

  const handleOnClickNewLanguage = (): void => {
    navigate(`/language`);
  };

  return (
    <LeftNav1>
      <SideMenuTop>
        <LogoFrame>
          <LogoLinguaSource1 />
        </LogoFrame>
        <SideMenu1 />
      </SideMenuTop>
      <SideMenuBottom>
        <Button2 variant="outlined" onClick={handleOnClickNewLanguage}>
          Add a New Language
        </Button2>
        <Frame51>
          <Account>{`Account`}</Account>
          <SignOut>{`Sign Out`}</SignOut>
          <Rectangle22></Rectangle22>
        </Frame51>
      </SideMenuBottom>
    </LeftNav1>
  );
}

export default LeftNav;
