import { useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import LanguageModel from "../model/LanguageModel";
import LanguageDataService from "../services/LanguageService";

interface UseLanguageQueryProps {
  id: number;
  onSuccess: (response: { data: LanguageModel }) => void;
  onError: (err: Error) => void;
}

export const useGetLanguage = ({
  id,
  onSuccess,
  onError,
}: UseLanguageQueryProps) => {
  const {
    isLoading: isLoadingLanguage,
    isFetching,
    isError,
    refetch,
  } = useQuery<{ data: LanguageModel }, Error>(
    "query-get-language",
    async () => {
      return LanguageDataService.get(id);
    },
    {
      retry: 1,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  const getLanguage = useCallback((): void => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    getLanguage();
  }, [id, getLanguage]);

  return {
    isLoadingLanguage,
    isFetching,
    isError,
  };
};
