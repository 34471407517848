import { useQuery } from "react-query";
import LanguageModel from "../model/LanguageModel";
import LanguageDataService from "../services/LanguageService";

interface UseLanguageQueryProps {
  id: number;
  payload: LanguageModel;
  isNew: boolean;
  onSuccess: (response: { data: { id: number } }) => void;
  onError: (err: Error) => void;
}

export const useSaveLanguage = ({
  id,
  payload,
  onSuccess,
  onError,
  isNew = false,
}: UseLanguageQueryProps) => {
  const {
    isLoading: isLoadingLanguage,
    isFetching,
    isError,
    refetch,
  } = useQuery<{ data: { id: number } }, Error>(
    "query-save-language",
    async () => {
      if (isNew) {
        return LanguageDataService.post(payload);
      }

      return LanguageDataService.put(id, payload);
    },
    {
      retry: 1,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  return {
    isLoadingLanguage,
    isFetching,
    isError,
    saveLanguage: refetch,
  };
};
