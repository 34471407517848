import { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styles } from "./styles";
import { NoteDialogProps } from "./NoteDialogProps";
import NoteDialogNew from "./NoteDialogNew";
import NoteDialogList from "./NoteDialogList";
import { theme as customTheme } from "../../utils/CustomTheme";

const LIST_DIALOG = "list";
const NEW_FORM_DIALOG = "new";

export default function NoteDialog(props: NoteDialogProps) {
  const [dialogType, setDialogType] = useState<string>(LIST_DIALOG);
  const { open, handleClose } = props;
  const descriptionElementRef = useRef<HTMLElement>(null);
  const fullScreen = useMediaQuery(customTheme.breakpoints.down("md"));

  const handleAddNote = () => {
    setDialogType(NEW_FORM_DIALOG);
  };

  const handleSaveNote = () => {
    setDialogType(LIST_DIALOG);
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="notes-dialog-title"
        open={open}
        scroll="paper"
        fullScreen={fullScreen}
        sx={styles.dialog}
      >
        {dialogType === LIST_DIALOG ? (
          <NoteDialogList {...props} handleAddNote={handleAddNote} />
        ) : (
          <NoteDialogNew
            {...props}
            handleAddNote={handleAddNote}
            onSave={handleSaveNote}
          />
        )}
      </Dialog>
    </div>
  );
}
