import { useState, KeyboardEvent, ChangeEvent } from "react";
import { Grid, Container, Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styles } from "./styles";
import SearchField from "../../components/SearchField/SearchField";

const Home = () => {
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchTextValue = event.target?.value;
    setSearchText(searchTextValue);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      navigate(`/languages?q=${searchText}`);
    }
  };

  const handleOnClickSearch = (): void => {
    navigate(`/languages?q=${searchText}`);
  };

  return (
    <Box
      sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
    >
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.homeTitle}>
              Languages <b>Search</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SearchField
              searchText={searchText}
              onChange={onChangeSearchText}
              onKeyPress={onKeyPress}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={styles.searchButton}
              onClick={handleOnClickSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
